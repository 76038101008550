import { CSSProperties, ReactNode } from 'react'

import { Loader } from '@/components/Loader'

import { cn } from '@/utils'

export interface SectionItemProps {
  label: string
  description?: string | ReactNode | ReactNode[]
  className?: string
  labelClassName?: string
  style?: CSSProperties
  loading?: boolean
  error?: string
}

export const SectionItem = ({
  error,
  loading,
  style,
  labelClassName,
  label,
  description,
  className
}: SectionItemProps) => {
  return (
    <div
      style={style}
      title={error}
      className={cn(
        'relative flex flex-col items-center justify-center gap-[20px] overflow-hidden rounded-[30px] border border-solid border-gray700 bg-gray800 py-[50px]',
        className
      )}
    >
      <Loader loading={!!loading} type='absolute' />
      {error && (
        <div className='absolute left-0 top-0 z-1 h-full w-full cursor-not-allowed bg-gray800/50' />
      )}
      <p
        title={label}
        className={cn(
          'long-text w-full px-[32px] text-center text-[36px] font-bold tracking-normal text-white',
          { blur: loading || error },
          labelClassName
        )}
      >
        {label}
      </p>
      {typeof description === 'string' ? (
        <p className='long-text px-[32px] text-center text-[20px] font-medium leading-[30px] text-white/50'>
          {description}
        </p>
      ) : (
        description
      )}
    </div>
  )
}
