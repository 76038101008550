export enum KycStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  STARTED = 'STARTED',
  VERIFIED = 'VERIFIED',
  VERIFIED_NO_SSN = 'VERIFIED_NO_SSN',
  FAILED = 'FAILED'
}
export interface IUser {
  id: string
  created_at: string
  updated_at: string
  first_name: string
  last_name: string
  username: string
  display_name: string | null
  email: string
  otp: string
  avatar: string | null
  pin: string | null
  last_login_at: string
  status: string
  membership_type: 'REGULAR' | 'SATOSHI_PLUS'
  fcm_token: string
  device_id: string
  ibex_account_id: string
  ibex_address_onchain: string
  ibex_address_lightning: string
  ibex_address_lightning_long: string
  is_notifications_enabled: boolean
  is_tracking_enabled: boolean
  is_kyc_blocked: boolean
  is_two_factor_enabled: boolean
  accrued_balance_msats: string
  daily_earn_rate_msats: string
  earn_started_at: string
  streak_count: number
  streak_started_at: string | null
  last_claimed_at: string
  earnings_msats: string
  earned_unclaimed_msats: string
  earned_unclaimed_updated_at: string | null
  secret: string
  kyc_status: KycStatusEnum
  kyc_failure_codes?: string[]
  is_deleted: boolean
  blocked: boolean
  withdrawals_paused: boolean
  rank: number
  rank_updated_at: Date

  date_of_birth?: string
  phone_number?: string
  address_street?: string
  address_street_2?: string
  address_city?: string
  address_subdivision?: string
  address_postal_code?: string
  address_country_code?: string

  app_version?: string
  device_type?: string

  exchange_balance_msats: string
  exchange_balance_usd: string
  exchange_total_send_usd: string
  exchange_total_recieved_usd: string
  exchange_total_bought_usd: string
  exchange_total_sold_usd: string

  starter_balance_msats: string
  // starter_balance_usd: string
  started_total_bought_usd: string
  started_total_sold_usd: string
}
