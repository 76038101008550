import { ReactNode } from 'react'

import { cn } from '@/utils'

export interface SectionOption {
  label: string
  rightContent?: string | ReactNode
}
interface SectionProps {
  title: string
  options: SectionOption[]
  avatar?: string
}

export const Section = ({ title, options, avatar }: SectionProps) => {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-row items-center justify-between'>
        <p className='text-[20px] font-bold tracking-normal text-white'>
          {title}
        </p>
        {avatar && (
          <img
            src={avatar}
            alt=''
            className='h-[100px] w-[100px] rounded-full bg-gray900'
          />
        )}
      </div>
      <div className='my-[20px] flex flex-col'>
        {options.map((option, index) => (
          <div
            key={index}
            className={cn(
              'flex flex-row items-center justify-between gap-[8px]',
              index !== options.length - 1 && 'mb-[15px]'
            )}
          >
            <p className='text-[14px] font-semibold text-white/50'>
              {option.label}
            </p>
            {typeof option.rightContent === 'string' ? (
              <p
                title={option.rightContent}
                className='long-text text-[14px] font-semibold text-white'
              >
                {option.rightContent}
              </p>
            ) : (
              option.rightContent
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
