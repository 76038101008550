import { ReactNode, useEffect, useRef, useState } from 'react'

import { cn } from '@/utils'

export interface TabItem {
  key: string
  label: string
  value: string
}

interface TabsProps {
  tabs: TabItem[]
  onTab?: (tabItem: TabItem) => void
  selectedTabKey?: string
  renderTabItem?: (tabItem: TabItem) => ReactNode
}

interface TabRect {
  width: number
  height: number
  left: number
  top: number
}

const ANIMATION_DURATION = 300
export const Tabs = ({
  tabs,
  selectedTabKey: propsSelectedTabKey,
  onTab
}: TabsProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tabsRef = useRef<Record<string, HTMLButtonElement | null>>({})
  const [loaded, setLoaded] = useState<boolean>(false)
  const [selectedTabKey, setSelectedTabKey] = useState<string | undefined>(
    propsSelectedTabKey || tabs[0]?.key
  )
  const [selectedTabRect, setSelectedTabRect] = useState<TabRect | undefined>()

  useEffect(() => {
    setSelectedTabKey(propsSelectedTabKey)
  }, [propsSelectedTabKey])

  useEffect(() => {
    const tabElement = tabsRef.current[selectedTabKey || '']
    if (!tabElement) {
      return
    }
    const handler = () => {
      setSelectedTabRect(calculateTabRect(tabElement))
    }
    handler()
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [selectedTabKey, loaded])

  const checkAllTabsDisplayed = () => {
    const elementsLoaded =
      Object.keys(tabsRef.current).length === tabs.length &&
      containerRef.current !== null

    if (elementsLoaded) {
      setTimeout(() => {
        setLoaded(true)
      }, ANIMATION_DURATION)
    }
  }

  const calculateTabRect = (
    tabElement: HTMLButtonElement
  ): TabRect | undefined => {
    const container = containerRef.current
    if (!container) {
      return
    }
    const { x: containerX, y: containerY } = container.getBoundingClientRect()
    const { x, y, width, height } = tabElement.getBoundingClientRect()

    return {
      left: x - containerX,
      width,
      height,
      top: y - containerY
    }
  }

  const getTabItem = (tabKey: string) => {
    return tabs.find(tab => tab.key === tabKey)
  }

  const onSelectTab = (tabKey: string) => {
    return () => {
      const selectedTab = getTabItem(tabKey)
      if (!selectedTab) {
        return
      }
      setSelectedTabKey(tabKey)
      onTab && onTab(selectedTab)

      const tabElement = tabsRef.current[tabKey]
      if (!tabElement) {
        return
      }

      setSelectedTabRect(calculateTabRect(tabElement))
    }
  }

  return (
    <div
      ref={containerRef}
      className='relative flex gap-[4px] rounded-[30px] bg-gray900 p-[5px]'
    >
      <div
        className='absolute rounded-[30px] bg-white/10 px-[20px] py-[8px] transition-all duration-300'
        style={{
          ...selectedTabRect,
          width: selectedTabRect ? selectedTabRect.width : 0
        }}
      />
      {tabs.map(tabItem => (
        <button
          className={cn(
            'cursor-pointer rounded-[30px] bg-white/0 px-[20px] py-[8px] text-[12px] font-bold text-white transition-all duration-300',
            tabItem.key !== selectedTabKey &&
              'hover:bg-white/5 active:bg-white/5'
          )}
          key={tabItem.key}
          ref={ref => {
            tabsRef.current[tabItem.key] = ref
            checkAllTabsDisplayed()
          }}
          onClick={onSelectTab(tabItem.key)}
        >
          {tabItem.label}
        </button>
      ))}
    </div>
  )
}
