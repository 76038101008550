import cn from 'clsx'
import { forwardRef } from 'react'

import { IField } from '@/components/Field/field.interface'

const Field = forwardRef<HTMLInputElement, IField>(
  (
    {
      placeholder,
      error,
      className,
      inputClassName,
      type = 'text',
      style,
      ...rest
    },
    ref
  ) => (
    <div className={cn('mt-2 w-full', className)} style={style}>
      <input
        placeholder={placeholder}
        type={type}
        ref={ref}
        className={cn(
          'w-full rounded-md border-2 border-gray500/20 bg-gray800 p-[20px] text-base font-bold text-white outline-none disabled:text-white/20',
          {
            '!border-red-500': error
          },
          inputClassName
        )}
        {...rest}
      />
      {error && <div className='mt-1 font-medium text-red-500'>*{error}</div>}
    </div>
  )
)

Field.displayName = 'Field'

export default Field
