import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { Button } from '@/components/DataTable/Button'
import { Drawer } from '@/components/Drawer'
import FailureCodeComponent from '@/components/FailureCode'
import Icon from '@/components/Icon'
import { Loader } from '@/components/Loader'
import ProtectedComponent from '@/components/ProtectedComponent'

import { IUser } from '@/types/account/user.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'
import { EditButton } from '@/routes/Users/Buttons/EditButton'
import { ActionButton } from '@/routes/Users/EditUser/ActionButton'
import { Section, SectionOption } from '@/routes/Users/EditUser/Section'
import { cn, formatNumber, millisatoshisToBTC } from '@/utils'

interface EditUserProps {
  user: IUser
}

export const EditUser = ({ user }: EditUserProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const {
    data: userData,
    isLoading,
    error
  } = useQuery({
    queryKey: ['users', user.id],
    queryFn: async () => {
      const resp = await api<IUser>(`/admin/users/${user.id}`)

      return resp.data
    },
    enabled: isOpened
  })

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['update-user', user.id],
    mutationFn: async (params: {
      url: string
      method: string
      errorTitle: string
      successMessage: string
    }) => {
      const resp = await api(params.url, {
        method: params.method
      })

      return resp.data
    },
    onError(error, context) {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || context.errorTitle)
    },
    onSuccess(data, context) {
      queryClient.invalidateQueries()
      if (context.successMessage === 'OTP') {
        const toastId = toast.success(
          <div className='flex flex-col gap-2'>
            <p>
              OTP sent to @{userData?.username} successfully.
              <br />
              OTP: <b className='text-lg font-bold'>{data}</b>
              <br />
              Click close to copy the OTP.
            </p>
            <Button
              variant={'destructive'}
              onClick={() => {
                navigator.clipboard.writeText(data)
                toast.dismiss(toastId)
              }}
              className='self-end'
            >
              Close
            </Button>
          </div>,
          {
            position: 'top-center',
            duration: 100000
          }
        )
      } else {
        toast.success(context.successMessage)
      }
      setIsOpened(false)
    }
  })

  const kycStatus = useMemo(() => {
    let status: string
    let className: string
    let failureCodes: string[] | undefined

    if (
      userData?.kyc_status.toLowerCase() === 'verified' ||
      userData?.kyc_status.toLowerCase() === 'verified_no_ssn'
    ) {
      status =
        userData?.kyc_status.toLowerCase() === 'verified_no_ssn'
          ? 'Verified (No SSN)'
          : 'Verified'
      className = 'text-lime500'
    } else if (userData?.kyc_status.toLowerCase() === 'started') {
      status = 'Pending'
      className = 'text-orange500'
      failureCodes = userData?.kyc_failure_codes
    } else if (userData?.kyc_status.toLowerCase() === 'failed') {
      status = 'Rejected'
      className = 'text-red500'
      failureCodes = userData?.kyc_failure_codes
    } else {
      status = 'Unverified'
      className = 'text-red500'
      failureCodes = userData?.kyc_failure_codes
    }

    return { status, className, failureCodes }
  }, [userData])

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) {
      return ''
    }
    const date = new Date(dateString)
    if (date.toString() === 'Invalid Date') {
      return '-'
    }

    return format(date, 'yyyy.MM.dd')
  }

  const handleBlock = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-block`,
      method: 'PUT',
      errorTitle: userData?.blocked
        ? 'An error occurred while unblocking the user.'
        : 'An error occurred while blocking the user.',
      successMessage: userData?.blocked ? 'User unblocked.' : 'User blocked.'
    })
  }

  const handleDelete = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-delete`,
      method: 'PUT',
      errorTitle: userData?.is_deleted
        ? 'An error occurred while restoring the user.'
        : 'An error occurred while soft deleting the user.',
      successMessage: userData?.is_deleted
        ? 'User restored.'
        : 'User soft deleted.'
    })
  }

  const handleWithdrawal = async () => {
    mutate({
      url: `admin/users/${user.id}/handle-withdrawal`,
      method: 'PUT',
      errorTitle: userData?.withdrawals_paused
        ? 'An error occurred while resuming withdrawals.'
        : 'An error occurred while pausing withdrawals.',
      successMessage: userData?.withdrawals_paused
        ? 'Withdrawals resumed.'
        : 'Withdrawals paused.'
    })
  }

  const handleResetTwoFactor = async () => {
    mutate({
      url: `admin/users/${user.id}/reset-2fa`,
      method: 'PUT',
      errorTitle: 'An error occurred while resetting the 2FA.',
      successMessage: '2FA reset.'
    })
  }

  const handleSendOtp = async () => {
    mutate({
      url: `admin/users/${user.id}/send-otp`,
      method: 'PUT',
      errorTitle: 'An error occurred while sending the OTP.',
      successMessage: 'OTP'
    })
  }

  return (
    <>
      <EditButton onClick={() => setIsOpened(true)} title='Edit' />
      <Drawer
        contentClassName='bg-gray800 border-solid border-gray500/50 w-full sm:w-[480px]'
        open={isOpened}
        position='right'
        onClose={() => setIsOpened(false)}
      >
        <Loader loading={isLoading || isPending} type='absolute' />
        {error ? (
          <div className='flex h-full flex-col items-center justify-center gap-1'>
            <p className='text-red500'>
              An error occurred while fetching the user data.
            </p>
            <p>{error.message}</p>
          </div>
        ) : !userData ? (
          <div className='flex h-full flex-col items-center justify-center gap-1'>
            <p className='text-red500'>No data found for this user.</p>
          </div>
        ) : (
          <div className='flex h-full flex-col gap-[40px] overflow-y-auto p-[24px] sm:px-[70px] sm:py-[60px]'>
            <div className='flex flex-row items-center justify-between'>
              <p
                title={userData?.id}
                className='long-text whitespace-nowrap pr-[16px] text-[24px] font-bold text-white'
              >
                ID: {userData?.id}
              </p>
              <Icon
                icon='Close'
                onClick={() => setIsOpened(false)}
                className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
              />
            </div>
            <Section
              title='Details'
              avatar={userData?.avatar || undefined}
              options={[
                {
                  label: 'Display Name',
                  rightContent: userData?.display_name || '-'
                },
                {
                  label: 'Legal Name',
                  rightContent:
                    userData?.first_name || userData?.last_name
                      ? `${userData?.first_name} ${userData?.last_name}`
                      : '-'
                },
                { label: 'Username', rightContent: `@${userData?.username}` },
                { label: 'Email', rightContent: userData?.email },
                {
                  label: 'Date Joined',
                  rightContent: formatDate(userData?.created_at)
                },
                {
                  label: 'KYC Status',
                  rightContent: (
                    <p
                      className={cn(
                        'text-[14px] font-semibold',
                        kycStatus.className
                      )}
                    >
                      {kycStatus.status}
                    </p>
                  )
                },
                {
                  label: 'KYC Reject Reason',
                  rightContent:
                    kycStatus.failureCodes &&
                    kycStatus.failureCodes.length > 0 ? (
                      <div className='flex w-full max-w-[250px] flex-col gap-0.5 text-right'>
                        {kycStatus.failureCodes.map(val => (
                          <FailureCodeComponent key={val} failureCode={val} />
                        ))}
                      </div>
                    ) : (
                      '-'
                    )
                },
                {
                  label: 'Membership Type',
                  rightContent:
                    userData?.membership_type === 'REGULAR'
                      ? 'Regular'
                      : 'Satoshi Plus'
                },
                {
                  label: 'Phone Number',
                  rightContent: userData?.phone_number || '-'
                },
                {
                  label: 'City',
                  rightContent: userData?.address_city || '-'
                },
                {
                  label: 'State',
                  rightContent: userData?.address_subdivision || '-'
                },
                {
                  label: 'Country',
                  rightContent: userData?.address_country_code || '-'
                },
                {
                  label: 'App Version',
                  rightContent: userData?.app_version || '-'
                },
                {
                  label: 'Device Type',
                  rightContent: userData?.device_type || '-'
                }
              ]}
            />
            <Section
              title='Stats'
              options={[
                {
                  label: 'BTC Balance (Exchange)',
                  rightContent: `${millisatoshisToBTC(new BigNumber(userData?.exchange_balance_msats || 0)).toFormat(8)}`
                },
                {
                  label: 'USD Balance (Exchange)',
                  rightContent: `$${formatNumber(userData?.exchange_balance_usd, '0.00')}`
                },
                {
                  label: 'Total Sent (Exchange)',
                  rightContent: `$${formatNumber(userData?.exchange_total_send_usd, '0.00')}`
                },
                {
                  label: 'Total Recieved (Exchange)',
                  rightContent: `$${formatNumber(userData?.exchange_total_recieved_usd, '0.00')}`
                },
                {
                  label: 'Total Bought (Exchange)',
                  rightContent: `$${formatNumber(userData?.exchange_total_bought_usd, '0.00')}`
                },
                {
                  label: 'Total Sold (Exchange)',
                  rightContent: `$${formatNumber(userData?.exchange_total_sold_usd, '0.00')}`
                },

                {
                  label: 'Balance (Starter)',
                  rightContent: `${millisatoshisToBTC(new BigNumber(userData?.starter_balance_msats || 0)).toFormat(8)}`
                },
                {
                  label: 'Total Send (Starter)',
                  rightContent: `$${formatNumber(userData?.started_total_bought_usd, '0.00')}`
                },
                {
                  label: 'Total Recieved (Starter)',
                  rightContent: `$${formatNumber(userData?.started_total_sold_usd, '0.00')}`
                }
              ]}
            />
            <Section
              title='Actions'
              options={
                [
                  userData?.avatar && {
                    label: 'Remove Avatar',
                    rightContent: (
                      <ProtectedComponent permissionSlug='users_update'>
                        <ActionButton
                          onClick={() =>
                            mutate({
                              url: `/admin/users/${user.id}/update-avatar`,
                              method: 'PATCH',
                              errorTitle:
                                'An error occurred while removing the avatar.',
                              successMessage: 'Avatar removed.'
                            })
                          }
                          title='Remove'
                        />
                      </ProtectedComponent>
                    )
                  },
                  userData?.blocked
                    ? {
                        label: 'Unblock Account',
                        rightContent: (
                          <ProtectedComponent permissionSlug='users_handle_block'>
                            <ActionButton
                              onClick={handleBlock}
                              title='Unblock'
                            />
                          </ProtectedComponent>
                        )
                      }
                    : {
                        label: 'Block Account',
                        rightContent: (
                          <ProtectedComponent permissionSlug='users_handle_block'>
                            <ActionButton onClick={handleBlock} title='Block' />
                          </ProtectedComponent>
                        )
                      },
                  userData?.is_two_factor_enabled && {
                    label: 'Reset 2FA',
                    rightContent: (
                      <ProtectedComponent permissionSlug='users_handle_block'>
                        <ActionButton
                          onClick={handleResetTwoFactor}
                          title='Reset'
                        />
                      </ProtectedComponent>
                    )
                  },
                  {
                    label: 'Send OTP',
                    rightContent: (
                      <ProtectedComponent permissionSlug='users_handle_block'>
                        <ActionButton onClick={handleSendOtp} title='Send' />
                      </ProtectedComponent>
                    )
                  },
                  userData?.withdrawals_paused
                    ? {
                        label: 'Resume Withdraw',
                        rightContent: (
                          <ProtectedComponent permissionSlug='users_handle_withdraw'>
                            <ActionButton
                              title='Resume'
                              onClick={handleWithdrawal}
                            />
                          </ProtectedComponent>
                        )
                      }
                    : {
                        label: 'Pause Withdraw',
                        rightContent: (
                          <ProtectedComponent permissionSlug='users_handle_withdraw'>
                            <ActionButton
                              title='Pause'
                              onClick={handleWithdrawal}
                            />
                          </ProtectedComponent>
                        )
                      },
                  userData?.is_deleted
                    ? {
                        label: 'Restore Account',
                        rightContent: (
                          <ProtectedComponent permissionSlug='users_delete'>
                            <ActionButton
                              title='Restore'
                              onClick={handleDelete}
                            />
                          </ProtectedComponent>
                        )
                      }
                    : {
                        label: 'Delete (soft)',
                        rightContent: (
                          <ProtectedComponent permissionSlug='users_delete'>
                            <ActionButton
                              title='Delete'
                              onClick={handleDelete}
                            />
                          </ProtectedComponent>
                        )
                      }
                ].filter(Boolean) as SectionOption[]
              }
            />
          </div>
        )}
      </Drawer>
    </>
  )
}
