import { NextUIProvider } from '@nextui-org/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { AuthProvider } from '@/components/AuthProvider'
import { Layout } from '@/components/Layout'
import ProtectedComponent from '@/components/ProtectedComponent'
import { ThemeProvider } from '@/components/Theme/Provider'
import AccountGroups from '@/routes/AccountGroups'
import AccountGroupById from '@/routes/AccountGroups/ByID'
import BankEddRequests from '@/routes/EDDRequests'
import Earn from '@/routes/Earn'
import { Health } from '@/routes/Health'
import { Home } from '@/routes/Home'
import KYCVerifications from '@/routes/KYCVerifications'
import Learn from '@/routes/Learn'
import Categories from '@/routes/Learn/Categories'
import Creators from '@/routes/Learn/Creators'
import Videos from '@/routes/Learn/Videos'
import { Login } from '@/routes/Login'
import { Metrics } from '@/routes/Metrics'
import NotFound from '@/routes/NotFound'
import { Notifications } from '@/routes/Notifications'
import { Permissions } from '@/routes/Organization'
import { Roles } from '@/routes/Organization/Roles'
import { TeamMembers } from '@/routes/Organization/TeamMembers'
import { Settings } from '@/routes/Settings'
import { Users } from '@/routes/Users'
import store, { persistor } from '@/store'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '*',
        element: <NotFound />
      },
      {
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Home />
          },
          {
            path: 'users',
            element: <Users />
          },
          {
            path: 'metrics',
            element: (
              <ProtectedComponent permissionSlug='metrics_view' isPage>
                <Metrics />
              </ProtectedComponent>
            )
          },
          {
            path: 'learn',
            element: <Learn />
          },
          {
            path: 'learn/creators',
            element: <Creators />
          },
          {
            path: 'learn/categories',
            element: <Categories />
          },
          {
            path: 'learn/videos',
            element: <Videos />
          },
          {
            path: 'earn',
            element: <Earn />
          },
          {
            path: 'notifications',
            element: <Notifications />
          },
          {
            path: 'account-groups',
            children: [
              {
                path: '',
                element: <AccountGroups />
              },
              {
                path: ':id',
                element: <AccountGroupById />
              }
            ]
          },
          {
            path: 'kyc-verifications',
            element: (
              <ProtectedComponent
                permissionSlug='kyc_verifications_view'
                isPage
              >
                <KYCVerifications />
              </ProtectedComponent>
            )
          },
          {
            path: 'edd-requests',
            element: (
              <ProtectedComponent permissionSlug='edd_requests_view' isPage>
                <BankEddRequests />
              </ProtectedComponent>
            )
          },
          {
            path: 'health',
            element: <Health />
          },
          {
            path: 'settings',
            element: <Settings />
          },
          {
            path: 'organization',
            element: (
              <ProtectedComponent permissionSlug='organization_view' isPage>
                <Permissions />
              </ProtectedComponent>
            ),
            children: [
              {
                path: 'members',
                element: <TeamMembers />
              },
              {
                path: 'roles',
                element: <Roles />
              }
            ]
          }
        ]
      }
    ]
  }
])

function App() {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false
        }
      }
    })
  )

  return (
    <QueryClientProvider client={client}>
      <NextUIProvider>
        <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <RouterProvider router={router} />
              <Toaster position='bottom-right' reverseOrder={false} />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </NextUIProvider>
    </QueryClientProvider>
  )
}

export default App
