import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import toast from 'react-hot-toast'

import { Section } from '@/components/InfoSection'
import { SectionItem } from '@/components/InfoSection/SectionItem'
import { SubDescriptionSectionItem } from '@/components/InfoSection/SectionItem/SubDescriptionSectionItem'
import { TabsSectionItem } from '@/components/InfoSection/SectionItem/TabsSectionItem'
import ProtectedComponent from '@/components/ProtectedComponent'

import {
  ExchangeDataInterface,
  FlashDataInterface,
  IUserMetrics,
  StarterDataInterface
} from '@/types/metrics/metrics.interface'

import api from '@/api'
import styles from '@/routes/Metrics/metrics.module.css'
import { formatMetricsData } from '@/routes/Metrics/metricsFormater'
import { cn } from '@/utils'

export function Metrics() {
  const {
    data: users,
    isLoading: usersLoading,
    isError: usersError
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await api.get<IUserMetrics>('/admin/metrics/users'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: exchangeData,
    isLoading: exchangeDataLoading,
    isError: exchangeDataError
  } = useQuery({
    queryKey: ['exchange'],
    queryFn: async () =>
      await api.get<ExchangeDataInterface>('/admin/metrics/exchange'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: flashData,
    isLoading: flashDataLoading,
    isError: flashDataError
  } = useQuery({
    queryKey: ['flash'],
    queryFn: async () =>
      await api.get<FlashDataInterface>('/admin/metrics/flash'),
    enabled: true,
    select: res => res.data
  })
  const {
    data: starterData,
    isLoading: starterDataLoading,
    isError: starterDataError
  } = useQuery({
    queryKey: ['starter'],
    queryFn: async () =>
      await api.get<StarterDataInterface>('/admin/metrics/starter'),
    enabled: true,
    select: res => res.data
  })

  const dataErrors = useMemo(() => {
    const errors = {
      users: usersError ? 'Could not get Users metrics' : '',
      exchange:
        (exchangeDataError && 'Could not get Exchange metrics') ||
        exchangeData?.error,
      flash:
        (flashDataError && 'Could not get Flash metrics') || flashData?.error,
      starter:
        (starterDataError && 'Could not get Starter metrics') ||
        starterData?.error
    }

    Object.entries(errors).map(([key, message]) => {
      if (!message) {
        return null
      }

      toast.error(message, {
        id: key
      })
      return null
    })

    return errors
  }, [
    usersError,
    exchangeData,
    exchangeDataError,
    flashData,
    flashDataError,
    starterData,
    starterDataError
  ])

  const metricsData = useMemo(() => {
    return formatMetricsData({
      userData: users,
      flash: flashData,
      exchange: exchangeData,
      starter: starterData
    })
  }, [users, flashData, exchangeData, starterData])

  return (
    <ProtectedComponent permissionSlug='metrics_view' isPage>
      <Helmet>
        <title>STBA Admin - Metrics</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white/15 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Metrics
        </h1>
        <section className='flex flex-col gap-10 md:gap-16'>
          <Section title='User Data'>
            {Object.entries(metricsData.userData).map(([key, item]) => (
              <SectionItem
                className={cn(
                  'w-full min-w-0 flex-none',
                  styles.sectionItemColumnFour
                )}
                key={key}
                label={item.label}
                description={item.description}
                loading={usersLoading}
                error={dataErrors.users}
              />
            ))}
          </Section>
          <Section title='Exchange' subtitle='ZeroHash'>
            <TabsSectionItem
              labelClassName='!text-orange550'
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnFour
              )}
              tabs={metricsData.exchange.bitcoinUnderCustody.tabs}
              description={metricsData.exchange.bitcoinUnderCustody.description}
              loading={exchangeDataLoading}
              error={dataErrors.exchange}
            />
            <TabsSectionItem
              labelClassName='!text-blue500'
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnFour
              )}
              tabs={metricsData.exchange.dollarsUnderCustody.tabs}
              description={metricsData.exchange.dollarsUnderCustody.description}
              loading={exchangeDataLoading}
              error={dataErrors.exchange}
            />
            <TabsSectionItem
              labelClassName='!text-lime500'
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnFour
              )}
              tabs={metricsData.exchange.buyVolume.tabs}
              description={metricsData.exchange.buyVolume.description}
              loading={exchangeDataLoading}
              error={dataErrors.exchange}
            />
            <TabsSectionItem
              labelClassName='!text-orange500'
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnFour
              )}
              tabs={metricsData.exchange.sellVolume.tabs}
              description={metricsData.exchange.sellVolume.description}
              loading={exchangeDataLoading}
              error={dataErrors.exchange}
            />
          </Section>
          <Section title='Flash' subtitle='Breez'>
            <SubDescriptionSectionItem
              labelClassName='!text-purple400'
              label={metricsData.flash.totalLightningBalances.label}
              description={metricsData.flash.totalLightningBalances.description}
              subDescription={
                metricsData.flash.totalLightningBalances.subDescription
              }
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnThree
              )}
              loading={flashDataLoading}
              error={dataErrors.flash}
            />
            <SubDescriptionSectionItem
              labelClassName='!text-lime500'
              label={metricsData.flash.volumeReceived.label}
              description={metricsData.flash.volumeReceived.description}
              subDescription={metricsData.flash.volumeReceived.subDescription}
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnThree
              )}
              loading={flashDataLoading}
              error={dataErrors.flash}
            />
            <SubDescriptionSectionItem
              labelClassName='!text-orange500'
              label={metricsData.flash.volumeSent.label}
              description={metricsData.flash.volumeSent.description}
              subDescription={metricsData.flash.volumeSent.subDescription}
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnThree
              )}
              loading={flashDataLoading}
              error={dataErrors.flash}
            />
            <TabsSectionItem
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnTwo
              )}
              tabs={metricsData.flash.paymentsMade.tabs}
              description={metricsData.flash.paymentsMade.description}
              loading={flashDataLoading}
              error={dataErrors.flash}
            />
            <TabsSectionItem
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnTwo
              )}
              tabs={metricsData.flash.channelsOpen.tabs}
              description={metricsData.flash.channelsOpen.description}
              loading={flashDataLoading}
              error={dataErrors.flash}
            />
          </Section>
          <Section title='Starter' subtitle='IBEX'>
            <SubDescriptionSectionItem
              labelClassName='!text-purple400'
              label={metricsData.starter.totalBalance.label}
              description={metricsData.starter.totalBalance.description}
              subDescription={metricsData.starter.totalBalance.subDescription}
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnThree
              )}
              loading={starterDataLoading}
              error={dataErrors.starter}
            />
            <SubDescriptionSectionItem
              labelClassName='!text-lime500'
              label={metricsData.starter.volumeReceived.label}
              description={metricsData.starter.volumeReceived.description}
              subDescription={metricsData.starter.volumeReceived.subDescription}
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnThree
              )}
              loading={starterDataLoading}
              error={dataErrors.starter}
            />
            <SubDescriptionSectionItem
              labelClassName='!text-orange500'
              label={metricsData.starter.volumeSent.label}
              description={metricsData.starter.volumeSent.description}
              subDescription={metricsData.starter.volumeSent.subDescription}
              className={cn(
                'w-full min-w-0 flex-none',
                styles.sectionItemColumnThree
              )}
              loading={starterDataLoading}
              error={dataErrors.starter}
            />
            <TabsSectionItem
              className={cn('w-full min-w-0 flex-none')}
              tabs={metricsData.starter.paymentsMade.tabs}
              description={metricsData.starter.paymentsMade.description}
              loading={starterDataLoading}
              error={dataErrors.starter}
            />
          </Section>
        </section>
      </section>
    </ProtectedComponent>
  )
}
