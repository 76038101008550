import { Switch } from '@nextui-org/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from '@/api'
import { errorCatch } from '@/api/error'
import { AddButton } from '@/components/Buttons/AddButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { Modal } from '@/components/Modal'
import { ICreateOrUpdateCategory } from '@/types/learn/category.interface'
import { EditButton } from '@/routes/Users/Buttons/EditButton'

interface Props {
  initial?: ICreateOrUpdateCategory
  url: string
}

const CreateOrUpdateCategory: React.FC<Props> = ({ initial, url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateCategory>({
    mode: 'onChange',
    defaultValues: initial || {
      name: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        name: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-category' : 'add-category'],
    mutationFn: (data: ICreateOrUpdateCategory) =>
      api(url, {
        method: initial ? 'PATCH' : 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(
        `Category ${initial ? 'updated' : 'created'} successfully!`,
        {
          id: initial ? 'update-category' : 'add-category'
        }
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || `Failed to ${initial ? 'update' : 'create'} category`,
        {
          id: initial ? 'update-category' : 'add-category'
        }
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateCategory> = async data =>
    mutate(data)

  return (
    <>
      {initial ? (
        <EditButton onClick={() => setIsOpen(true)} title='Edit' />
      ) : (
        <AddButton title='Add Category' onClick={() => setIsOpen(true)} />
      )}
      <Modal visible={isOpen} onClose={() => setIsOpen(false)}>
        <Loader type='absolute' loading={isPending} />
        <Modal.Header
          title={initial ? 'Edit Category' : 'Create Category'}
          closable
        />
        <Modal.Content>
          <form className='mt-4 flex flex-col gap-5'>
            <Field
              placeholder='name'
              {...register('name', {
                required: 'Category Name is required',
                minLength: {
                  value: 3,
                  message: 'Category Name should be at least 3 characters long'
                }
              })}
              error={errors.name?.message}
            />
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <label className='flex cursor-pointer select-none items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Visibility</p>
                  <Switch
                    color='success'
                    isSelected={field.value === 1}
                    onValueChange={isSelected =>
                      field.onChange(isSelected ? 1 : 0)
                    }
                  />
                </label>
              )}
            />
            <FormButton
              onClick={handleSubmit(onSubmit)}
              preventDefault
              stopPropagation
              title='Confirm'
              className='mt-2 bg-orange550 text-white'
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default CreateOrUpdateCategory
