import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'
import { INotification } from '@/types/notifications/notification.interface'
import formatId from '@/utils/formatId'

const User = ({
  avatar,
  name,
  nameClassName
}: {
  avatar: string | null
  name: string
  nameClassName?: string
}) => {
  return (
    <div className='flex items-center gap-[8px] overflow-hidden'>
      {avatar ? (
        <div
          className='h-[32px] w-[32px] flex-none rounded-full bg-cover bg-center'
          style={{
            backgroundImage: `url(${avatar})`
          }}
        />
      ) : (
        <div className='h-[32px] w-[32px] flex-none rounded-full bg-white/20' />
      )}
      <p className={nameClassName} title={name}>
        {name}
      </p>
    </div>
  )
}

export const columns: ColumnDef<INotification>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'title',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Title' />
    }
  },
  {
    accessorKey: 'body',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Body' />
    },
    cell: ({ row }) => {
      return <p className='text-white opacity-50'>{row.original.body}</p>
    }
  },
  {
    accessorKey: 'receiver',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Receiver' />
    },
    cell: ({ row }) => {
      return row.original.send_to_all ? (
        <p className='text-foreground'>To All</p>
      ) : row.original.reciever_group ? (
        <p className='text-foreground/50'>
          {row.original.reciever_group?.name}
        </p>
      ) : (
        <User
          name={
            row.original.receiver_user?.display_name ||
            `@${row.original.receiver_user?.username}`
          }
          nameClassName='text-foreground'
          avatar={row.original.receiver_user?.avatar || ''}
        />
      )
    }
  },
  {
    accessorKey: 'sender',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Sender' />
    },
    cell: ({ row }) => {
      return (
        <User
          name={row.original.sender.name}
          nameClassName='text-white'
          avatar={row.original.sender.avatar}
        />
      )
    }
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return <p className='text-white opacity-50'>{row.original.type}</p>
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='opacity-50'>-</p>
      }

      return <p className='opacity-50'>{format(date, 'yyyy.MM.dd')}</p>
    }
  }
]
