import { Switch } from '@nextui-org/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { ErrorMessage } from '@/components/Message/ErrorMessage'
import { Modal } from '@/components/Modal'

import { ICreateOrUpdateVideo, IVideo } from '@/types/learn/video.interface'

import {
  getVideoDataByUrl,
  getVideoId,
  isValidYouTubeUrl
} from '@/utils/youtube'

import { errorCatch } from '@/api/error'

import api from '@/api'
import CategoriesSelect from '@/routes/Learn/Videos/Autocomplete/CategoriesSelect'
import CreatorsSelect from '@/routes/Learn/Videos/Autocomplete/CreatorsSelect'
import { EditButton } from '@/routes/Users/Buttons/EditButton'

interface Props {
  initial?: IVideo
  url: string
}

const CreateOrUpdateVideo: React.FC<Props> = ({ initial, url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm<ICreateOrUpdateVideo>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          title: initial.title,
          video_id: initial.video_id,
          video_url: initial.video_url,
          video_upload_timestamp: initial.video_upload_timestamp,
          creators: initial.creators ? initial.creators.map(c => c.id) : [],
          categories: initial.categories
            ? initial.categories.map(c => c.id)
            : [],
          status: initial.status
        }
      : {
          title: '',
          video_id: '',
          video_url: '',
          video_upload_timestamp: '',
          categories: [],
          creators: [],
          status: 1
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            title: initial.title,
            video_id: initial.video_id,
            video_url: initial.video_url,
            video_upload_timestamp: initial.video_upload_timestamp,
            creators: initial.creators ? initial.creators.map(c => c.id) : [],
            categories: initial.categories
              ? initial.categories.map(c => c.id)
              : [],
            status: initial.status
          }
        : {
            title: '',
            video_id: '',
            video_url: '',
            video_upload_timestamp: '',
            categories: [],
            creators: [],
            status: 1
          }
    )
  }, [initial, reset])

  const videoUrl = watch('video_url')

  const { data, isLoading } = useQuery({
    queryKey: ['video-data', videoUrl],
    queryFn: async () => {
      const resp = await getVideoDataByUrl(videoUrl)

      if (!resp || !resp.items || resp.items.length === 0) {
        return null
      }

      return {
        thumbnail: resp.items[0].snippet.thumbnails.medium.url,
        publishedAt: resp.items[0].snippet.publishedAt,
        videoId: resp.items[0].id
      }
    },
    enabled: isOpen && !!videoUrl && isValidYouTubeUrl(videoUrl),
    select: data => data
  })

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-video' : 'add-video', initial?.id],
    mutationFn: async (video: ICreateOrUpdateVideo) =>
      api(url, {
        method: initial ? 'PATCH' : 'POST',
        data: {
          ...video,
          video_id: data?.videoId || getVideoId(video.video_url),
          video_upload_timestamp: data?.publishedAt || new Date().toISOString(),
          categories: video.categories,
          creators: video.creators
        }
      }),
    onSuccess: () => {
      toast.success(
        initial ? 'Video updated successfully' : 'Video added successfully'
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        initial
          ? 'Failed to update video. '
          : 'Failed to add video. ' + errorMessage
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateVideo> = async data =>
    mutate(data)

  return (
    <>
      {initial ? (
        <EditButton onClick={() => setIsOpen(true)} title='Edit' />
      ) : (
        <AddButton title='Add Video' onClick={() => setIsOpen(true)} />
      )}

      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        overflow={false}
        className='z-[50]'
      >
        <Loader loading={isPending} type='absolute' />
        <Modal.Header
          title={initial ? 'Edit Video' : 'Create Video'}
          closable
        />
        <Modal.Content>
          <form className='mt-4 flex flex-col gap-5'>
            {data && (
              <div className='relative flex flex-1 flex-col items-center justify-between gap-[10px] rounded-[10px] border-2 border-gray500/20 bg-gray800 p-[20px]'>
                <span className='text-[12px] font-bold leading-[15px] text-white'>
                  Thumbnail Image
                </span>
                <div
                  className='h-[100px] w-[140px] overflow-hidden rounded-[10px] bg-cover bg-center'
                  style={{
                    backgroundImage: `url(${data.thumbnail})`
                  }}
                />
                <Loader loading={isLoading} type='absolute' />
              </div>
            )}
            <div className='flex flex-col gap-2'>
              <Controller
                name='creators'
                control={control}
                render={({ field }) => {
                  return (
                    <CreatorsSelect
                      creators={field.value}
                      setCreators={field.onChange}
                    />
                  )
                }}
              />
              {errors.creators?.message && (
                <ErrorMessage message={errors.creators.message} />
              )}
            </div>
            <Field
              placeholder='Video URL'
              error={errors.video_url?.message}
              {...register('video_url', { required: 'Video URL is required' })}
            />
            <Field
              placeholder='Video Title'
              error={errors.title?.message}
              {...register('title', { required: 'Title is required' })}
            />
            <div className='flex flex-col gap-2'>
              <Controller
                name='categories'
                control={control}
                render={({ field }) => {
                  return (
                    <CategoriesSelect
                      initial={initial?.categories.map(c => ({
                        label: c.name,
                        value: c.id
                      }))}
                      categories={field.value}
                      setCategories={field.onChange}
                    />
                  )
                }}
              />
              {errors.categories?.message && (
                <ErrorMessage message={errors.categories.message} />
              )}
            </div>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <label className='flex cursor-pointer select-none items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Visibility</p>
                  <Switch
                    color='success'
                    isSelected={field.value === 1}
                    onValueChange={isSelected =>
                      field.onChange(isSelected ? 1 : 0)
                    }
                  />
                </label>
              )}
            />
            <FormButton
              onClick={() => handleSubmit(onSubmit)()}
              preventDefault
              stopPropagation
              title='Confirm'
              className='mt-2 bg-orange550 text-white'
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default CreateOrUpdateVideo
